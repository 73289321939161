<template>
  <match-media v-slot="{ mobile }" class="realty-edit-general">
    <template v-if="!mobile">
      <edit-realty-basic />
      <edit-realty-about-object />
      <edit-objects-realty-description v-if="stateModel.category" />
      <edit-objects-realty-seo v-if="isNmh && stateModel.category && isRoleAdmin" />
    </template>

    <template v-else>
      <div class="realty-edit-general__content">
        <edit-realty-basic v-show="activeSection === 'basic' || activeSection === 'general'" />
        <edit-realty-about-object v-show="activeSection === 'aboutObject'" />
        <edit-objects-realty-description v-show="activeSection === 'description'" />
        <edit-objects-realty-seo v-show="isNmh && activeSection === 'seo'" />
      </div>
    </template>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import EditRealtyBasic from '@/components/ObjectsRealty/Edit/Basic.vue'
import EditRealtyAboutObject from '@/components/ObjectsRealty/Edit/AboutObject.vue'
import EditObjectsRealtyDescription from '@/components/ObjectsRealty/Edit/Description.vue'
import EditObjectsRealtySeo from '@/components/ObjectsRealty/Edit/Seo.vue'
import { mapGetters, mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { ABOUT_OBJECT, MODULE_REALTY } from '@/store/modules/realty/realty.types'

export default {
  name: 'ObjectsRealtyEditGeneral',

  components: {
    MatchMedia,
    EditRealtyBasic,
    EditRealtyAboutObject,
    EditObjectsRealtyDescription,
    EditObjectsRealtySeo
  },
  props: {
    activeSection: { type: [String, Object], default: () => null },
    isBasicSectionFilled: { type: Boolean, default: false },
    isDescriptionSectionFilled: { type: Boolean, default: false },
    isSeoSectionFilled: { type: Boolean, default: false },
    isAboutObjectSectionFilled: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[ABOUT_OBJECT]
    })
  }
}
</script>
