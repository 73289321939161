<template>
  <svg class="icon icon--tick" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 10.8L3.2 8l-1 1L6 12.6l8-8-1-1-7 7.1z" />
  </svg>
</template>

<script>
export default {
  name: 'VCheck'
}
</script>
