var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset-wrapper',{attrs:{"model":_vm.model,"type":_vm.$options.SECTION_TYPE,"module":_vm.$options.MODULE_REALTY}},[_c('v-section',{staticClass:"realty-edit-about-object",attrs:{"title":"Об объекте"}},[_c('v-form-row',{staticClass:"realty-edit-about-object__row realty-edit-about-object__owner-info"},[_c('v-form-field',{staticClass:"realty-edit-about-object__owner-info-id",attrs:{"label":"ID"}},[_c('v-input',{attrs:{"value":_vm.id,"disabled":""}})],1),_c('v-form-field',{staticClass:"realty-edit-about-object__owner-info-name",attrs:{"label":"СОБСТВЕННИК"}},[_c('v-input',{attrs:{"value":_vm.owner,"disabled":""}})],1)],1),_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ТИП СДЕЛКИ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.DEAL_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.dealType),callback:function ($$v) {_vm.$set(_vm.model, "dealType", $$v)},expression:"model.dealType"}})]}}])})],1),(_vm.model.dealType)?[(_vm.isRentDealType)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"СРОК АРЕНДЫ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.RENT_DURATION_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.rentDuration),callback:function ($$v) {_vm.$set(_vm.model, "rentDuration", $$v)},expression:"model.rentDuration"}})]}}],null,false,4109039935)})],1):_vm._e(),_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"КАТЕГОРИЯ НЕДВИЖИМОСТИ","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.REALTY_PURPOSE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.realtyPurpose),callback:function ($$v) {_vm.$set(_vm.model, "realtyPurpose", $$v)},expression:"model.realtyPurpose"}})]}}],null,false,638206360)})],1),(_vm.model.realtyPurpose)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ТИП НЕДВИЖИМОСТИ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"loading":_vm.categoriesLoading,"options":_vm.realtyCategories,"is-error":!!validationErrors.length},model:{value:(_vm.model.category),callback:function ($$v) {_vm.$set(_vm.model, "category", $$v)},expression:"model.category"}})]}}],null,false,3906495939)})],1):_vm._e(),(_vm.model.category)?[_c('v-form-row',{staticClass:"realty-edit-about-object__row realty-edit-about-object__realty-info"},[(_vm.isHousesGroup)?_c('div',{staticClass:"realty-edit-about-object__realty-cadastral"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field",attrs:{"label":"КАДАСТРОВЫЙ НОМЕР ДОМА"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"mask":_vm.$options.CADASTRAL_NUMBER_MASK,"is-error":!!validationErrors.length},model:{value:(_vm.model.houseCadastralNumber),callback:function ($$v) {_vm.$set(_vm.model, "houseCadastralNumber", $$v)},expression:"model.houseCadastralNumber"}})]}}],null,false,2376993540)})],1):_vm._e(),(_vm.isHousesGroup || _vm.isLandPlotsGroup)?_c('div',{staticClass:"realty-edit-about-object__realty-cadastral"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field",attrs:{"label":"КАДАСТРОВЫЙ НОМЕР УЧАСТКА"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"mask":_vm.$options.CADASTRAL_NUMBER_MASK,"is-error":!!validationErrors.length},model:{value:(_vm.model.landCadastralNumber),callback:function ($$v) {_vm.$set(_vm.model, "landCadastralNumber", $$v)},expression:"model.landCadastralNumber"}})]}}],null,false,3620085319)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('div',{staticClass:"realty-edit-about-object__realty-cadastral"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field",attrs:{"label":"КАДАСТРОВЫЙ НОМЕР КВАРТИРЫ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"mask":_vm.$options.CADASTRAL_NUMBER_MASK,"is-error":!!validationErrors.length},model:{value:(_vm.model.apartmentCadastralNumber),callback:function ($$v) {_vm.$set(_vm.model, "apartmentCadastralNumber", $$v)},expression:"model.apartmentCadastralNumber"}})]}}],null,false,2624736804)})],1):_vm._e()]),_c('v-form-row',{staticClass:"realty-edit-about-object__row realty-edit-about-object__realty-info"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-price",attrs:{"label":"СТОИМОСТЬ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-money',{attrs:{"is-error":!!validationErrors.length},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v(_vm._s(_vm.currencyIcon))])]},proxy:true}],null,true),model:{value:(_vm.model.price),callback:function ($$v) {_vm.$set(_vm.model, "price", $$v)},expression:"model.price"}})]}}],null,false,3274552690)}),_c('v-form-field',{attrs:{"label":"ВАЛЮТА","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.CURRENCY_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.auxiliaryCurrency),callback:function ($$v) {_vm.$set(_vm.model, "auxiliaryCurrency", $$v)},expression:"model.auxiliaryCurrency"}})]}}],null,false,61072653)})],1),(_vm.isRentDealType)?_c('v-form-row',{staticClass:"realty-edit-about-object__row realty-edit-about-object__realty-info"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-communal",attrs:{"label":"КОММУНАЛЬНЫЕ ПЛАТЕЖИ","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.COMMUNAL_PAYMENTS_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.communalPaymentsIncluded),callback:function ($$v) {_vm.$set(_vm.model, "communalPaymentsIncluded", $$v)},expression:"model.communalPaymentsIncluded"}})]}}],null,false,3360750887)}),_c('v-form-field',{attrs:{"label":"СЧЕТЧИКИ","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.COUNTERS_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.countersIncluded),callback:function ($$v) {_vm.$set(_vm.model, "countersIncluded", $$v)},expression:"model.countersIncluded"}})]}}],null,false,3491635704)})],1):_vm._e(),(_vm.isRentDealType)?_c('v-form-row',{staticClass:"realty-edit-about-object__row realty-edit-about-object__realty-info"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-deposit",attrs:{"label":"ЗАЛОГ СОБСТВЕННИКУ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-money',{attrs:{"is-error":!!validationErrors.length},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v(_vm._s(_vm.currencyIcon))])]},proxy:true}],null,true),model:{value:(_vm.model.deposit),callback:function ($$v) {_vm.$set(_vm.model, "deposit", $$v)},expression:"model.deposit"}})]}}],null,false,206205391)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-prepayment",attrs:{"label":"ПРЕДОПЛАТА","separate-label":""}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.$options.PREPAYMENT_TYPE_OPTIONS,"label":"label","reduce":function (option) { return option.value; }},model:{value:(_vm.model.prepaymentMonths),callback:function ($$v) {_vm.$set(_vm.model, "prepaymentMonths", $$v)},expression:"model.prepaymentMonths"}})],1)],1):_vm._e(),(_vm.isSaleDealType)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ВОЗМОЖНА ИПОТЕКА","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.MORTGAGE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.mortgage),callback:function ($$v) {_vm.$set(_vm.model, "mortgage", $$v)},expression:"model.mortgage"}})]}}],null,false,747938215)})],1):_vm._e(),_c('v-form-row',{staticClass:"realty-edit-about-object__row realty-edit-about-object__realty-info"},[(!_vm.isLandPlotsGroup)?_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-area",attrs:{"label":_vm.isHousesGroup ? 'ПЛОЩАДЬ ОБЪЕКТА' : 'ОБЩАЯ ПЛОЩАДЬ',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v("кв.м")])]},proxy:true}],null,true),model:{value:(_vm.model.area),callback:function ($$v) {_vm.$set(_vm.model, "area", $$v)},expression:"model.area"}})]}}],null,false,3079664547)}):_vm._e(),(_vm.isHousesGroup || _vm.isLandPlotsGroup)?_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-plottage",attrs:{"label":"ПЛОЩАДЬ УЧАСТКА","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v("сот.")])]},proxy:true}],null,true),model:{value:(_vm.model.plottage),callback:function ($$v) {_vm.$set(_vm.model, "plottage", $$v)},expression:"model.plottage"}})]}}],null,false,1609065101)}):_vm._e(),(_vm.isHousesGroup)?_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-build-year",attrs:{"label":"ГОД ПОСТРОЙКИ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v("год")])]},proxy:true}],null,true),model:{value:(_vm.model.buildYear),callback:function ($$v) {_vm.$set(_vm.model, "buildYear", $$v)},expression:"model.buildYear"}})]}}],null,false,853799886)}):_vm._e(),(_vm.isApartmentsGroup)?[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-area",attrs:{"label":"ЖИЛАЯ ПЛОЩАДЬ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v("кв.м")])]},proxy:true}],null,true),model:{value:(_vm.model.livingArea),callback:function ($$v) {_vm.$set(_vm.model, "livingArea", $$v)},expression:"model.livingArea"}})]}}],null,false,1040544688)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-area",attrs:{"label":"ПЛОЩАДЬ КУХНИ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v("кв.м")])]},proxy:true}],null,true),model:{value:(_vm.model.kitchenArea),callback:function ($$v) {_vm.$set(_vm.model, "kitchenArea", $$v)},expression:"model.kitchenArea"}})]}}],null,false,3614394581)})]:_vm._e()],2),(!_vm.isLandPlotsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info\n     realty-edit-about-object__realty-info--count"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"КОЛ-ВО СПАЛЕН","rules":_vm.isHousesGroup ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.bedroomsCount),callback:function ($$v) {_vm.$set(_vm.model, "bedroomsCount", $$v)},expression:"model.bedroomsCount"}})]}}],null,false,66832141)}),(_vm.isApartmentsGroup)?_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-rooms-count",attrs:{"label":"КОЛ-ВО КОМНАТ","rules":"required","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"is-error":!!validationErrors.length,"clearable":false,"options":_vm.$options.ROOM_COUNT_OPTIONS,"label":"label","reduce":function (option) { return option.value; }},model:{value:(_vm.model.roomCount),callback:function ($$v) {_vm.$set(_vm.model, "roomCount", $$v)},expression:"model.roomCount"}})]}}],null,false,3601651328)}):_vm._e(),(_vm.isHousesGroup)?[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"КОЛ-ВО САНУЗЛОВ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.wcCount),callback:function ($$v) {_vm.$set(_vm.model, "wcCount", $$v)},expression:"model.wcCount"}})]}}],null,false,284536598)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"КОЛ-ВО ЭТАЖЕЙ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.floors),callback:function ($$v) {_vm.$set(_vm.model, "floors", $$v)},expression:"model.floors"}})]}}],null,false,1236592042)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"КОЛ-ВО УРОВНЕЙ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.layers),callback:function ($$v) {_vm.$set(_vm.model, "layers", $$v)},expression:"model.layers"}})]}}],null,false,252198449)})]:_vm._e()],2):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info\n     realty-edit-about-object__realty-info--count"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"ЛОДЖИИ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.loggiaCount),callback:function ($$v) {_vm.$set(_vm.model, "loggiaCount", $$v)},expression:"model.loggiaCount"}})]}}],null,false,4002045769)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"БАЛКОНЫ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.balconiesCount),callback:function ($$v) {_vm.$set(_vm.model, "balconiesCount", $$v)},expression:"model.balconiesCount"}})]}}],null,false,4266671216)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info\n     realty-edit-about-object__realty-info--count"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"РАЗДЕЛЬНЫЕ С/У"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.separateWcCount),callback:function ($$v) {_vm.$set(_vm.model, "separateWcCount", $$v)},expression:"model.separateWcCount"}})]}}],null,false,2861971827)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"СОВМЕЩЕННЫЕ С/У"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.combinedWcCount),callback:function ($$v) {_vm.$set(_vm.model, "combinedWcCount", $$v)},expression:"model.combinedWcCount"}})]}}],null,false,1752476371)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ТИП РЕМОНТА","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.RENOVATION_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.renovationType),callback:function ($$v) {_vm.$set(_vm.model, "renovationType", $$v)},expression:"model.renovationType"}})]}}],null,false,2962242808)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info\n     realty-edit-about-object__realty-info--count"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"ЭТАЖ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.floor),callback:function ($$v) {_vm.$set(_vm.model, "floor", $$v)},expression:"model.floor"}})]}}],null,false,1907151641)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-item",attrs:{"label":"КОЛ-ВО ЭТАЖЕЙ В ДОМЕ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.floors),callback:function ($$v) {_vm.$set(_vm.model, "floors", $$v)},expression:"model.floors"}})]}}],null,false,1236592042)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-housing-complex",attrs:{"label":"НАЗВАНИЕ ЖК"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.model.housingComplexName),callback:function ($$v) {_vm.$set(_vm.model, "housingComplexName", $$v)},expression:"model.housingComplexName"}})]}}],null,false,3505373514)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-housing-complex",attrs:{"label":"ГОД ПОСТРОЙКИ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"realty-edit-about-object__input-append"},[_vm._v("год")])]},proxy:true}],null,true),model:{value:(_vm.model.buildYear),callback:function ($$v) {_vm.$set(_vm.model, "buildYear", $$v)},expression:"model.buildYear"}})]}}],null,false,853799886)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-housing-complex",attrs:{"label":"ID ЖК ДЛЯ ЦИАН"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.housingComplexId),callback:function ($$v) {_vm.$set(_vm.model, "housingComplexId", $$v)},expression:"model.housingComplexId"}})]}}],null,false,918997901)})],1):_vm._e(),(!_vm.isLandPlotsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ТИП ДОМА","separate-label":"","rules":_vm.isHousesGroup ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.houseTypeOptions,"is-error":!!validationErrors.length},model:{value:(_vm.model.houseType),callback:function ($$v) {_vm.$set(_vm.model, "houseType", $$v)},expression:"model.houseType"}})]}}],null,false,3206047490)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ПАРКОВКА","separate-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.PARKING_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.parkingLot),callback:function ($$v) {_vm.$set(_vm.model, "parkingLot", $$v)},expression:"model.parkingLot"}})]}}],null,false,1628926071)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info"},[_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-elevators",attrs:{"label":"ПАССАЖИРСКИЕ ЛИФТЫ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.elevatorCount),callback:function ($$v) {_vm.$set(_vm.model, "elevatorCount", $$v)},expression:"model.elevatorCount"}})]}}],null,false,1004332400)}),_c('v-form-field',{staticClass:"realty-edit-about-object__realty-info-elevators",attrs:{"label":"ГРУЗОВЫЕ ЛИФТЫ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.serviceLiftCount),callback:function ($$v) {_vm.$set(_vm.model, "serviceLiftCount", $$v)},expression:"model.serviceLiftCount"}})]}}],null,false,1794398376)})],1):_vm._e(),(_vm.isApartmentsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row\n     realty-edit-about-object__realty-info"},[_c('v-form-field',{attrs:{"label":"ОКНА","separate-label":""}},[_c('div',{staticClass:"realty-edit-about-object__checkboxes"},[_c('v-checkbox',{staticClass:"realty-edit-about-object__checkbox",model:{value:(_vm.model.windowsCourtyard),callback:function ($$v) {_vm.$set(_vm.model, "windowsCourtyard", $$v)},expression:"model.windowsCourtyard"}},[_vm._v("Во двор")]),_c('v-checkbox',{staticClass:"realty-edit-about-object__checkbox",model:{value:(_vm.model.windowsOutdoor),callback:function ($$v) {_vm.$set(_vm.model, "windowsOutdoor", $$v)},expression:"model.windowsOutdoor"}},[_vm._v("На улицу")])],1)])],1):_vm._e(),(_vm.isHousesGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"ТИП ДОМА (ДЛЯ АВИТО)","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.AVITO_WALLS_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.avitoWallsType),callback:function ($$v) {_vm.$set(_vm.model, "avitoWallsType", $$v)},expression:"model.avitoWallsType"}})]}}],null,false,1741123399)})],1):_vm._e(),(_vm.isHousesGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field",attrs:{"label":"ТИП ОТДЕЛКИ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.FACING_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.facingType),callback:function ($$v) {_vm.$set(_vm.model, "facingType", $$v)},expression:"model.facingType"}})]}}],null,false,4117575512)})],1):_vm._e(),(_vm.isHousesGroup || _vm.isLandPlotsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field",attrs:{"label":"ТИП УЧАСТКА","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('select-options',{attrs:{"options":_vm.$options.PLOT_TYPE_OPTIONS,"is-error":!!validationErrors.length},model:{value:(_vm.model.plotType),callback:function ($$v) {_vm.$set(_vm.model, "plotType", $$v)},expression:"model.plotType"}})]}}],null,false,1240643832)})],1):_vm._e(),(_vm.isHousesGroup || _vm.isLandPlotsGroup)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{attrs:{"label":"КОММУНИКАЦИИ","separate-label":""}},[_c('div',{staticClass:"realty-edit-about-object__checkboxes"},[_c('v-checkbox',{staticClass:"realty-edit-about-object__checkbox",model:{value:(_vm.model.isGas),callback:function ($$v) {_vm.$set(_vm.model, "isGas", $$v)},expression:"model.isGas"}},[_vm._v("Газ")]),_c('v-checkbox',{staticClass:"realty-edit-about-object__checkbox",model:{value:(_vm.model.isElectricity),callback:function ($$v) {_vm.$set(_vm.model, "isElectricity", $$v)},expression:"model.isElectricity"}},[_vm._v("Электричество")]),_c('v-checkbox',{staticClass:"realty-edit-about-object__checkbox",model:{value:(_vm.model.isSewerage),callback:function ($$v) {_vm.$set(_vm.model, "isSewerage", $$v)},expression:"model.isSewerage"}},[_vm._v("Канализация")]),_c('v-checkbox',{staticClass:"realty-edit-about-object__checkbox",model:{value:(_vm.model.isWater),callback:function ($$v) {_vm.$set(_vm.model, "isWater", $$v)},expression:"model.isWater"}},[_vm._v("Водоснабжение")])],1)])],1):_vm._e(),_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field realty-edit-about-object__field--select",attrs:{"label":_vm.isApartmentsGroup ? 'В КВАРТИРЕ' : 'ТЭГИ',"separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"loading":_vm.tagsLoading,"multiple":"","options":_vm.tagsOptions,"label":"name","close-on-select":false,"reduce":function (tag) { return tag.id; },"is-error":!!validationErrors.length},model:{value:(_vm.model.tags),callback:function ($$v) {_vm.$set(_vm.model, "tags", $$v)},expression:"model.tags"}})]}}],null,false,1585859808)})],1),(_vm.isRoleAdmin)?_c('v-form-row',{staticClass:"realty-edit-about-object__row"},[_c('v-form-field',{staticClass:"realty-edit-about-object__field realty-edit-about-object__field--youtube",attrs:{"label":"ССЫЛКА YOUTUBE"}},[_c('v-input',{model:{value:(_vm.model.youtubeUrl),callback:function ($$v) {_vm.$set(_vm.model, "youtubeUrl", $$v)},expression:"model.youtubeUrl"}})],1)],1):_vm._e()]:_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }