<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_REALTY">
    <v-section title="ОТВЕТСТВЕННЫЙ АГЕНТ" class="realty-edit-responsible-agent">
      <template #header-append>
        <v-searchrow v-model="search" class="realty-edit-responsible-agent__searchrow" />
      </template>

      <div class="realty-edit-responsible-agent__list">
        <validation-provider rules="required">
          <agents-select v-model="agent" :options="filteredAgents" />
        </validation-provider>
      </div>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import VSection from '@/components/common/VSection.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import AgentsSelect from '@/components/Agent/AgentsSelect.vue'
import { agentsService } from '@/services/http'
import { mapState } from 'vuex'
import { MODULE_REALTY, RESPONSIBLE_AGENT } from '@/store/modules/realty/realty.types'
import cloneDeep from 'lodash.clonedeep'
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'

const SECTION_TYPE = RESPONSIBLE_AGENT

export default {
  SECTION_TYPE,
  MODULE_REALTY,
  name: 'ObjectsRealtyEditResponsibleAgent',
  components: { FieldsetWrapper, AgentsSelect, VSection, VSearchrow },
  props: {
    profileMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {},
      search: '',
      agentList: [],
      loading: false
    }
  },
  computed: {
    ...mapState(MODULE_REALTY, {
      stateModel: state => state[SECTION_TYPE]
    }),
    filteredAgents() {
      return this.agentList.filter(agent => agent.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    agent: {
      get() {
        const { agent } = this.model
        if (typeof agent === 'number') {
          return this.agentList.find(a => a.id === agent)
        }
        return agent
      },
      set(val) {
        this.model.agent = val
      }
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)

    this.fetchAgents()
  },

  methods: {
    fetchAgents() {
      this.loading = true
      agentsService
        .getList()
        .then(({ results }) => {
          this.agentList = results
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
